var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { chakraComponents, Select as ChakraSelect, } from 'chakra-react-select';
import { getChakraStyles } from './models';
import { Flex, Icon, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import Body from '../Typography/Body/Body';
import InputWrapper from './InputWrapper';
import { MdArrowDropDown } from 'react-icons/md';
const SelectAtom = ({ size = 'medium', label, value, isMultiSelect, options, onChange, renderOption, renderSelection, isDisabled = false, placeholder, isInvalid = false, }) => {
    const inputHeight = size === 'small' ? 6 : 9;
    const customComponents = {
        Option: (_a) => {
            var props = __rest(_a, []);
            return (_jsx(chakraComponents.Option, Object.assign({}, props, { children: renderOption ? (renderOption(props.data)) : (_jsx(Flex, { alignItems: 'center', flex: '1', gap: 2, children: _jsx(Body, { noOfLines: 1, children: props.data.label }) })) })));
        },
        DropdownIndicator: () => {
            return (_jsx(Flex, { px: 2, children: _jsx(Icon, { w: 6, h: 6, as: MdArrowDropDown }) }));
        },
        SingleValue: (_a) => {
            var props = __rest(_a, []);
            return (_jsx(chakraComponents.SingleValue, Object.assign({}, props, { children: renderSelection ? (renderSelection(props.data)) : (_jsx(Flex, { alignItems: 'center', flex: '1', gap: 2, children: _jsx(Body, { noOfLines: 1, children: props.data.label }) })) })));
        },
        IndicatorSeparator: () => {
            return _jsx(_Fragment, {});
        },
    };
    return (_jsx(Flex, { w: 'full', minH: inputHeight, children: _jsxs(InputGroup, { children: [label ? (_jsx(InputLeftAddon, { borderLeftRadius: '8px', color: 'white', background: 'primary.blue.500', px: 3, h: 'full', fontSize: '14px', borderColor: 'primary.blue.500', children: label })) : null, _jsx(ChakraSelect, { isDisabled: isDisabled, value: value, onChange: onChange, placeholder: placeholder, menuPortalTarget: document.body, isSearchable: false, isMulti: isMultiSelect, options: options, components: customComponents, chakraStyles: getChakraStyles(inputHeight, label), isInvalid: isInvalid })] }) }));
};
const Select = ({ options, onChange, renderSelection, renderOption, value, selectLabel, errorMessage, label, size, isRequired = false, isMultiSelect = false, isDisabled = false, placeholder, }) => {
    return (_jsx(InputWrapper, { label: label, error: errorMessage, isRequired: isRequired, size: size, children: _jsx(SelectAtom, { size: size, onChange: onChange, value: value, options: options, renderOption: renderOption, renderSelection: renderSelection, isMultiSelect: isMultiSelect, isDisabled: isDisabled, label: selectLabel, placeholder: placeholder, isInvalid: !!errorMessage }) }));
};
export default Select;
